import { Autocomplete, FormControl, FormHelperText, Grid, MenuItem, Select, TextField } from '@mui/material';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useSubActivityList from 'hooks/consume_api/query/useSubActivityList';
export default function BasicForm(props: any) {
    const { formik, measurements, frequencies, indicatorTypes, dataEntryInputs, relationships } = props;
    const { data, isFetching } = useSubActivityList();

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Unit of measurement :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <Select
                    id="uom"
                    name="uom"
                    label="Unit of measurement"
                    value={formik.values.uom}
                    onChange={(e: any) => {
                        formik.setFieldValue('uom', e.target.value);
                        console.log('uom', e.target.value);
                    }}
                    style={{ width: '100%' }}
                >
                    {measurements.map((m: any, i: number) => {
                        return (
                            <MenuItem key={i} value={m.id}>
                                {m.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Frequency :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <Select
                    id="frequency"
                    name="frequency"
                    value={formik.values.frequency}
                    onChange={(e: any) => formik.setFieldValue('frequency', e.target.value)}
                    style={{ width: '100%' }}
                >
                    {frequencies.map((f: any, i: number) => {
                        return (
                            <MenuItem key={i} value={f.id}>
                                {f.frequency}
                            </MenuItem>
                        );
                    })}
                </Select>
                <FormHelperText>Please enter frequency</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Type :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <Autocomplete
                    id="indicator_type"
                    value={formik.values.indicator_type}
                    onChange={(e: any, value) => {
                        formik.setFieldValue('indicator_type', value);
                    }}
                    disableClearable
                    options={indicatorTypes}
                    renderInput={(params) => <TextField {...params} label="" />}
                />
                <FormHelperText>Please enter type</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Data Entry Input :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <Autocomplete
                    id="data_entry_type"
                    value={formik.values.data_entry_type}
                    onChange={(e: any, value) => {
                        formik.setFieldValue('data_entry_type', value);
                    }}
                    disableClearable
                    options={dataEntryInputs}
                    renderInput={(params) => <TextField {...params} label="" />}
                />
                <FormHelperText>Please enter your full name</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' }, whiteSpace: 'normal' }}>
                    Relationship between progress and overall cumulative values :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <Autocomplete
                    value={formik.values.progress_vs_cumulative}
                    onChange={(e: any, value) => {
                        formik.setFieldValue('progress_vs_cumulative', value);
                    }}
                    disableClearable
                    options={relationships}
                    renderInput={(params) => <TextField {...params} label="" />}
                />
                <FormHelperText>Please enter relationship</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Baseline Time :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} fullwidth={true}>
                    <DatePicker
                        label="Date picker"
                        value={formik.values.baseline_time}
                        onChange={(e: any) => formik.setFieldValue('baseline_time', e)}
                        renderInput={(params: any) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Mid term multiplying factor :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <TextField
                    fullWidth
                    id="baseline_value"
                    name="midTermMultiplyingFactor"
                    onChange={formik.handleChange}
                    value={formik.values.midTermMultiplyingFactor}
                />
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Baseline value :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <TextField
                    fullWidth
                    id="baseline_value"
                    name="baseline_value"
                    onChange={formik.handleChange}
                    value={formik.values.baseline_value}
                />
                <FormHelperText>Please enter baseline value</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Assignee :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <Autocomplete
                    onChange={(e: any, value) => {
                        console.log(value);
                    }}
                    disableClearable
                    options={[]}
                    renderInput={(params) => <TextField {...params} label="" />}
                />
                <FormHelperText>Please enter assigne</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Data sources :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                <TextField fullWidth id="data_source" name="data_source" onChange={formik.handleChange} value={formik.values.data_source} />
                <FormHelperText>Please enter data sources</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Data Collection Types :
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                {/* <TextField
                    fullWidth
                    id="data_collection_types"
                    name="data_collection_types"
                    onChange={formik.handleChange}
                    value={formik.values.data_collection_types}
                /> */}
                <FormControl fullWidth>
                    <InputLabel id="data-collection-types-label">Data Collection Types</InputLabel>
                    <Select
                        fullWidth
                        labelId="data-collection-types-label"
                        id="data_collection_types"
                        name="data_collection_types"
                        value={formik.values.data_collection_types}
                        onChange={formik.handleChange}
                        label="Data Collection Types"
                    >
                        <MenuItem value="Manual">Manual</MenuItem>
                        <MenuItem value="Activity mapping">Activity mapping</MenuItem>
                    </Select>
                </FormControl>
                <FormHelperText>Please enter data collection methods</FormHelperText>
            </Grid>
            {formik.values.data_collection_types === "Activity mapping" && <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <InputLabel horizontal sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    Sub activity :
                </InputLabel>

            </Grid>}
            {!isFetching && formik.values.data_collection_types === "Activity mapping" && data && <Grid item xs={12} sm={9} lg={6}>
                <Autocomplete
                    options={data?.data}
                    multiple
                    value={
                        (formik.values.subActivities || []).length > 0
                            ? formik.values.subActivities.map((subActivity: any) => {
                                return (
                                    data?.data.find((item: any) => item.value_text === subActivity) || {
                                        value: subActivity,
                                        value_text: subActivity
                                    }
                                );
                            })
                            : []
                    }
                    getOptionLabel={(option) => option?.value || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(event, newValue) => {
                        const selectedValues = newValue.map((item) => item.value);
                        formik.setFieldValue('subActivities', selectedValues);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth label="Sub activity" />}
                />
            </Grid>}
        </Grid>
    );
}
