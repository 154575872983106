export const activityKeys = {
    all: ['activities'] as const,
    lists: () => [...activityKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...activityKeys.lists(), { filters }] as const,
    details: () => [...activityKeys.all, 'detail'] as const,
    detail: (id: string) => [...activityKeys.details(), id] as const
};
export const userKeys = {
    all: ['users'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...userKeys.lists(), { filters }] as const,
    details: () => [...userKeys.all, 'detail'] as const,
    detail: (id: string) => [...userKeys.details(), id] as const
};
export const activityStatusKeys = {
    all: ['activityStatuses'] as const,
    lists: () => [...activityStatusKeys.all, 'list'] as const,
    list: (filters: string) => [...activityStatusKeys.lists(), { filters }] as const,
    details: () => [...activityStatusKeys.all, 'detail'] as const,
    detail: (id: string) => [...activityStatusKeys.details(), id] as const
};
export const activityPhaseKeys = {
    all: ['activityPhases'] as const,
    lists: () => [...activityPhaseKeys.all, 'list'] as const,
    list: (filters: string) => [...activityPhaseKeys.lists(), { filters }] as const,
    details: () => [...activityPhaseKeys.all, 'detail'] as const,
    detail: (id: string) => [...activityPhaseKeys.details(), id] as const
};
export const indicatorKeys = {
    all: ['indicators'] as const,
    lists: () => [...indicatorKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...indicatorKeys.lists(), { filters }] as const,
    details: () => [...indicatorKeys.all, 'detail'] as const,
    detail: (id: string) => [...indicatorKeys.details(), id] as const
};
export const expenditureKeys = {
    all: ['expenditures'] as const,
    lists: () => [...expenditureKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...expenditureKeys.lists(), { filters }] as const,
    details: () => [...expenditureKeys.all, 'detail'] as const,
    detail: (id: string) => [...expenditureKeys.details(), id] as const
};
export const uomKeys = {
    all: ['uoms'] as const,
    lists: () => [...uomKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...uomKeys.lists(), { filters }] as const,
    details: () => [...uomKeys.all, 'detail'] as const,
    detail: (id: string) => [...uomKeys.details(), id] as const
};
export const collectionFrequencyKeys = {
    all: ['collectionFrequencys'] as const,
    lists: () => [...collectionFrequencyKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...collectionFrequencyKeys.lists(), { filters }] as const,
    details: () => [...collectionFrequencyKeys.all, 'detail'] as const,
    detail: (id: string) => [...collectionFrequencyKeys.details(), id] as const
};
export const rptKeys = {
    all: ['rpts'] as const,
    lists: () => [...rptKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...rptKeys.lists(), { filters }] as const,
    details: () => [...rptKeys.all, 'detail'] as const,
    detail: (id: string) => [...rptKeys.details(), id] as const
};
export const rpKeys = {
    all: ['rps'] as const,
    lists: () => [...rpKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...rpKeys.lists(), { filters }] as const,
    details: () => [...rpKeys.all, 'detail'] as const,
    detail: (id: string) => [...rpKeys.details(), id] as const
};
export const formMediaKeys = {
    all: ['formMedias'] as const,
    lists: () => [...formMediaKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...formMediaKeys.lists(), { filters }] as const,
    details: () => [...formMediaKeys.all, 'detail'] as const,
    detail: (id: string) => [...formMediaKeys.details(), id] as const
};

export const groupKeys = {
    all: ['groups'] as const,
    lists: () => [...groupKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...groupKeys.lists(), { filters }] as const,
    details: () => [...groupKeys.all, 'detail'] as const,
    detail: (id: string) => [...groupKeys.details(), id] as const
};

export const geoLocationKeys = {
    all: ['geoLocations'] as const,
    lists: () => [...geoLocationKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...geoLocationKeys.lists(), { filters }] as const,
    details: () => [...geoLocationKeys.all, 'detail'] as const,
    detail: (id: string) => [...geoLocationKeys.details(), id] as const
};

export const eventKeys = {
    all: ['events'] as const,
    lists: () => [...eventKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...eventKeys.lists(), { filters }] as const,
    details: () => [...eventKeys.all, 'detail'] as const,
    detail: (id: string) => [...eventKeys.details(), id] as const
};

export const eventMemberKeys = {
    all: ['eventMembers'] as const,
    lists: () => [...eventMemberKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...eventMemberKeys.lists(), { filters }] as const,
    details: () => [...eventMemberKeys.all, 'detail'] as const,
    detail: (id: string) => [...eventMemberKeys.details(), id] as const
};
export const availableEventUserKeys = {
    all: ['availableEventUsers'] as const,
    lists: () => [...availableEventUserKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...availableEventUserKeys.lists(), { filters }] as const,
    details: () => [...availableEventUserKeys.all, 'detail'] as const,
    detail: (id: string) => [...availableEventUserKeys.details(), id] as const
};

export const inputKeys = {
    all: ['inputs'] as const,
    lists: () => [...inputKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...inputKeys.lists(), { filters }] as const,
    details: () => [...inputKeys.all, 'detail'] as const,
    detail: (id: string) => [...inputKeys.details(), id] as const
};

export const inputMemberKeys = {
    all: ['inputMembers'] as const,
    lists: () => [...inputMemberKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...inputMemberKeys.lists(), { filters }] as const,
    details: () => [...inputMemberKeys.all, 'detail'] as const,
    detail: (id: string) => [...inputMemberKeys.details(), id] as const
};

export const availableInputUserKeys = {
    all: ['availableInputUsers'] as const,
    lists: () => [...availableInputUserKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...availableInputUserKeys.lists(), { filters }] as const,
    details: () => [...availableInputUserKeys.all, 'detail'] as const,
    detail: (id: string) => [...availableInputUserKeys.details(), id] as const
};

export const cardDataKeys = {
    all: ['cardData'] as const,
    lists: () => [...cardDataKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...cardDataKeys.lists(), { filters }] as const,
    details: () => [...cardDataKeys.all, 'detail'] as const,
    detail: (id: string) => [...cardDataKeys.details(), id] as const
};

export const chartDataKeys = {
    all: ['chartData'] as const,
    lists: () => [...chartDataKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...chartDataKeys.lists(), { filters }] as const,
    details: () => [...chartDataKeys.all, 'detail'] as const,
    detail: (id: string) => [...chartDataKeys.details(), id] as const
};

export const projectActivityKey = 'projectActivity';
export const memberForProjectActivity = 'memberForProjectActivity';
export const projectMember = 'projectMember';
export const memberProfile = 'memberProfile';
export const subActivityBadc = 'subActivityBadc';
export const subActivityGain = 'subActivityGain';
export const subActivityDam = 'subActivityDam';
export const subActivityDae = 'subActivityDae';
export const subActivityBari = 'subActivityBari';
export const subActivityFao = 'subActivityFao';
export const locationList = 'locationList';
export const fiscalYearList = 'fiscalYearList';
export const workPlanListByAgencyAndFiscalYear = 'workPlanListByAgencyAndFiscalYear';
export const memberInstanceList = 'memberInstanceList';
export const dashboardBeneficiaryKey = 'dashboardBeneficiaryKey';
export const dashboardInputKey = 'dashboardInputKey';
export const dashboardTrainingKey = 'dashboardTrainingKey';
export const demonstrationKey = 'dashboardDemonstrationKey';
export const dashboardInfrastructureKey = 'dashboardInfrastructure';
export const demonstrationListKey = 'demonstrationList';
export const infrastructureListKey = 'infrastructureList';
export const validationListKey = 'validationListKey';
export const validationListBySubActivityKey = 'validationListBySubActivityKey';
export const projectDetailsKey = 'projectDetailsKey';
export const roleListKey = 'roleList';
export const permissionListKey = 'permissionListKey';
export const subActivityKey = 'subActivity';
export const frequenciesKey = 'frequenciesKey';
export const disAggregationListKey = 'disAggregationListKey';
export const baselineDisaggregation = 'baselineDisaggregation';
export const logframeYearKey = 'logframeYearKey';
export const logFrameReportkey = 'logFrameReportkey';

export const frequencyKeys = {
    all: ['frequencies'] as const,
    lists: () => [...frequencyKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...frequencyKeys.lists(), { filters }] as const,
    details: () => [...frequencyKeys.all, 'detail'] as const,
    detail: (id: string) => [...frequencyKeys.details(), id] as const
};

export const unitOfMeasurementKeys = {
    all: ['unitsOfMeasurement'] as const,
    lists: () => [...unitOfMeasurementKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...unitOfMeasurementKeys.lists(), { filters }] as const,
    details: () => [...unitOfMeasurementKeys.all, 'detail'] as const,
    detail: (id: string) => [...unitOfMeasurementKeys.details(), id] as const
};
