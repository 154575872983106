import React, { useEffect, useState, lazy } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { sampleForm } from '../constant';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Grid, InputLabel, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, Autocomplete } from '@mui/material';
// import Loadable from 'ui-component/Loadable';
import { gridSpacing } from 'store/constant';
import _ from 'lodash';
import useSnackbar from 'hooks/useSnackbar';
import ActivityAdd from 'features/Activity/Add';
import { SDGType } from './constant';
import { getSdgList } from '../Service';
import { checkEmptyString } from 'utils/Helpers';

export default function FormDialog(props: any) {
    const [simpleName, setSimpleName] = useState<any>('');
    const [nodeCode, setNodeCode] = useState<any>('');
    const [formAttributes, setFormAttributes] = useState<any>({});
    const [oneSubmit, setOneSubmit] = useState<boolean>(false);
    const [aggregatedIndicatorSelected, setAggregatedIndicatorSelected] = useState<boolean>(false);
    const [sdgList, setSdgList] = useState<SDGType[]>([]);
    const [selectedSdgList, setSelectedSdgList] = useState<SDGType[]>([]);
    const [canSave, setCanSave] = useState<boolean>(false);

    const toastMsg = useSnackbar();

    const handleSubmit = () => {
        console.log(props.nodeProps);
        if (_.isEmpty(simpleName) || checkEmptyString(simpleName)) {
            toastMsg('Please enter a name', 'error');
        } else if (_.isEmpty(nodeCode) || checkEmptyString(nodeCode)) {
            toastMsg('Please enter a code', 'error');
        } else {
            //  this state has used to prevent multiple clicks;
            setOneSubmit(true);
            if (!oneSubmit) {
                const editDict: any = {};
                if (props.nodeProps.edit) {
                    editDict.id = props.nodeProps.node.node.uid;
                    editDict.parent = props.nodeProps.node?.parentNode?.uid;
                }
                props.onSubmit({
                    ...editDict,
                    name: simpleName,
                    code: nodeCode,
                    config: props.nodeProps.config,
                    aggregatedIndicator: props.isIndicatorNode && aggregatedIndicatorSelected,
                    selectedSdgList
                });
            }
            setTimeout(() => {
                setOneSubmit(false);
            }, 1000);
        }
    };

    const handleActivitySubmit = (activityOb: any) => {
        console.log('Activity Submit: ', props.nodeProps);
        props.onActivitySubmit({ ...activityOb, config: props.nodeProps.config });
    };

    const checkCanSave = () => {
        if (_.isEmpty(simpleName) || checkEmptyString(simpleName) || _.isEmpty(nodeCode) || checkEmptyString(nodeCode)) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    };

    useEffect(() => {
        checkCanSave();
    }, [simpleName, nodeCode]);

    useEffect(() => {
        const postbackUrl: string = sampleForm && sampleForm.submission_url;
        const formUuid: string = sampleForm && sampleForm.uuid;
        const dataJson: any = sampleForm && sampleForm.data_json;
        const formJson: any = sampleForm && sampleForm.json;
        const defaultLanguage: string = formJson.default_language && formJson.default_language;
        const userInput: any = dataJson ? dataJson : {};

        setFormAttributes({
            csv: {},
            defaultLanguage,
            errorList: [],
            formJson,
            formUuid,
            isLoading: false,
            media: {},
            postbackUrl,
            userInput
        });

        console.log('props.nodeProps :---:: ', props.nodeProps);

        if (props?.nodeProps?.edit) {
            setNodeCode(props.nodeProps.node?.node?.code);
            setSimpleName(props.nodeProps.node?.node?.title);
            setAggregatedIndicatorSelected(props.nodeProps.node?.node?.isAggregatedIndicator);
            const initialSdgList: SDGType[] = props.nodeProps.node?.node?.sdgList?.map((sdgItem: any) => {
                return { id: sdgItem.sdg.id, sdgName: sdgItem.sdg.sdgName };
            });
            setSelectedSdgList(initialSdgList);
        } else {
            setNodeCode('');
            setSimpleName('');
            setAggregatedIndicatorSelected(false);
            setSelectedSdgList([]);
        }
    }, [props.nodeProps]);

    const updateSdgList = async () => {
        const response = await getSdgList();
        if (response) {
            const dataList: SDGType[] = response.data.map((data: any) => {
                return {
                    id: data.id,
                    sdgName: data.sdgName
                };
            });
            setSdgList(dataList);
        }
    };

    useEffect(() => {
        if (props.open) {
            updateSdgList();
        } else {
            setSdgList([]);
            setSelectedSdgList([]);
        }
    }, [props.open]);

    const language: string = formAttributes.defaultLanguage === 'default' ? 'English' : formAttributes.defaultLanguage;
    const isActivity = () => {
        return props.nodeProps.config && props.nodeProps.config.data && props.nodeProps.config.data.attributes.nodeJson.data.isActivity;
    };

    const hasSdgSelection = () => {
        return props.nodeProps.config && props.nodeProps.config.data && props.nodeProps.config.data.attributes.nodeJson.data.isSdgBased;
    };

    console.log(' form dialog is getting called ', props);
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={isActivity() ? 'lg' : 'sm'}
                open={props.open}
                onClose={() => props.closeNewNodePopup()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {props.nodeProps.edit ? 'Edit' : 'New'}{' '}
                    {props.nodeProps.config && props.nodeProps.config.data && props.nodeProps.config.data.attributes.nodeJson.data.name}
                </DialogTitle>

                <DialogContent>
                    {isActivity() ? (
                        <ActivityAdd info={props.nodeProps} onSubmit={handleActivitySubmit} closeDialog={() => props.closeNewNodePopup()} />
                    ) : (
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputLabel> Code*: </InputLabel>
                                <TextField fullWidth value={nodeCode} onChange={(e: any) => setNodeCode(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel> Name*: </InputLabel>
                                <TextField fullWidth value={simpleName} onChange={(e: any) => setSimpleName(e.target.value)} />
                            </Grid>
                            {hasSdgSelection() && (
                                <Grid item xs={12}>
                                    <InputLabel> SDG: </InputLabel>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            disableCloseOnSelect
                                            id="checkboxes-tags-role"
                                            options={sdgList}
                                            value={selectedSdgList}
                                            getOptionLabel={(option: any) => option.sdgName}
                                            renderOption={(_props: any, option: any, { selected }: any) => {
                                                const selectedSdgIdList: number[] = selectedSdgList.map((item: SDGType) => item.id);
                                                return (
                                                    <li {..._props}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected || selectedSdgIdList.includes(option.id)}
                                                        />
                                                        {option.sdgName}
                                                    </li>
                                                );
                                            }}
                                            onChange={(event: any, value: any) => {
                                                setSelectedSdgList((prev) => value);
                                                console.log('event :: ', event);
                                                console.log('value :: ', value);
                                            }}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {props.isIndicatorNode && (
                                <Grid item={true} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={aggregatedIndicatorSelected}
                                                    onChange={(e) => {
                                                        setAggregatedIndicatorSelected(e.target.checked);
                                                    }}
                                                />
                                            }
                                            label="Aggregated Indicator"
                                        />
                                    </FormGroup>
                                </Grid>
                            )}
                            <Grid item xs={4} />
                            <Grid item xs={2}>
                                <Button onClick={handleSubmit} color="primary" variant="outlined" disabled={!canSave}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={() => props.closeNewNodePopup()} color="error" variant="outlined">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
