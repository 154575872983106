import defaultImage from 'assets/images/placeholder/default.png';
import { BASEURL } from './Constants';

export const countUnderscoresAfterSubstring = (inputString: string, substring: string) => {
    // Find the index of the substring in the input string
    var index = inputString.indexOf(substring);

    // If the substring is found
    if (index !== -1) {
        // Get the substring after the target substring
        var substringAfter = inputString.substring(index + substring.length);

        // Count the number of underscores in the remaining substring
        var underscoreCount = (substringAfter.match(/_/g) || []).length;

        return underscoreCount;
    } else {
        // If the substring is not found, return -1 or handle it as needed
        return -1;
    }
};

export const fullFilePath = (picPath: string) => {
    if (!picPath) {
        return defaultImage;
    }
    if (picPath.includes('http')) {
        return picPath;
    }
    return `${BASEURL}${picPath
        ?.split('/')
        .filter((v) => v !== '')
        .join('/')}`;
};

export const checkNullInfo = (info: string | null | undefined) => {
    if (info) {
        return info;
    }
    return '---';
};

export const getFieldLabel = () => {};

export const getToken = (storageName: string) => {
    let token = null;
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe) {
        token = window.localStorage.getItem(storageName);
    } else {
        token = window.sessionStorage.getItem(storageName);
    }
    return token;
};

export const setToken = (storageName: string, value: string) => {
    let token = null;
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe) {
        token = window.localStorage.setItem(storageName, value);
    } else {
        token = window.sessionStorage.setItem(storageName, value);
    }
    return token;
};

export const errMsg = (ex: any) => {
    if (ex.response.data.errors) {
        return ex.response.data.errors.map((e: any) => e.detail).join(', ');
    }
    return ex.message;

    //    console.log('eror', ex);
};

export const scrollAndHighlight = (elementId: string, parentDepth: number = 0, highlight: boolean = true) => {
    const selectedItemElement: HTMLElement = document.getElementById(elementId)!;

    const getFinalElementToHighlight = (node: HTMLElement, currentNodeDepth: number): HTMLElement => {
        if (currentNodeDepth === parentDepth) {
            return node;
        }
        return getFinalElementToHighlight(node?.parentNode as HTMLElement, currentNodeDepth + 1);
    };

    if (selectedItemElement) {
        selectedItemElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        if (highlight) {
            const elementToHighlight: HTMLElement = getFinalElementToHighlight(selectedItemElement, 0);
            elementToHighlight?.classList.add('highlighted-item');
        }
    }
};

export const checkEmptyString = (str: string) => {
    // Regular expression to match only spaces
    const regex = /^\s*$/;
    // Test the string against the regular expression
    return regex.test(str);
};

export const checkValidNumber = (value: string) => {
    const validNumberRegex = /^\d{0,10}(\.\d{1,2})?$/;
    return validNumberRegex.test(value) ? true : false;
};
